.chart-legend, .bar-legend, .line-legend, .pie-legend, .radar-legend, .polararea-legend, .doughnut-legend {
  list-style-type: none;
  margin-top: 5px;
  text-align: center;

  li {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    margin-bottom: 4px;
    border-radius: 5px;
    padding: 2px 8px 2px 28px;
    font-size: smaller;
    cursor: default;

    span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }
  }
}